const metaData = {
    SITE_NAME: `OxyContin® (oxycodone HCl) Extended-Release Tablets`,
    SITE_DEFAULT_DESCRIPTION: `OxyContin carries a Boxed Warning and contains oxycodone, a Schedule II controlled substance with an abuse potential similar to other Schedule II opioids.`,
    SITE_DEFAULT_KEYWORDS: ["OxyContin", "purdue"],
    PATIENT_JOB_CODE: `MR-08621 10/2024`,
    HCP_JOB_CODE: `MR-08621 10/2024`,
    CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 200;
const siteOptions = {
    INDICATIONS: ["hcp", "patient"],
    HEADER_TYPE: "fixed",
    STICKY_HEIGHT: ISI_HEIGHT,
    ISI_OPTIONS: {
        TYPE: "sticky",
        STICKY_STYLES: {
            backgroundColor: "#ffffff",
            height: ISI_HEIGHT,
            width: "100%",
            boxShadow: "0px 3px 10px 5px #d8d8d8",
            paddingTop: 5,
        },
    },
};
const hcpModalInfo = {
    TITLE: ``,
    BODY: `<h2>Are you a Healthcare Professional or <br/>Patient/Caregiver?</h2>`,
    BUTTON_ONE_CAPTION: `I am a healthcare professional`,
    BUTTON_TWO_CAPTION: `I am a patient or caregiver`,
    CLASS_NAME: `brandx-modal brandx-hcp-modal`,
};
const dailymedLinkInfo = {
    TITLE: `This link directs you to the National Library of Medicine's DailyMed website, which houses FDA-approved product labeling information.<span class="new_line"><br><br>Do you wish to leave OxyContin.com?</span>`,
    LINK_CLASS_NAME: `brandx-external-link`,
    OVERLAY_CLASS_NAME: `brandx-modal brandx-external-modal`,
};
const externalLinkInfo = {
    TITLE: `You are leaving OxyContin.com and entering a site that is neither the property of nor controlled by Purdue Pharma L.P. or its subsidiaries.<span class="new_line"><br><br>Do you wish to leave OxyContin.com?</span>`,
    LINK_CLASS_NAME: `brandx-external-link`,
    OVERLAY_CLASS_NAME: `brandx-modal brandx-external-modal`,
};

const appConfigs = {
    metaData: metaData,
    siteOptions: siteOptions,
    hcpModalInfo: hcpModalInfo,
    externalLinkInfo: externalLinkInfo,
    dailymedLinkInfo: dailymedLinkInfo,
};

export default appConfigs;
