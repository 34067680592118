exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-important-safety-information-js": () => import("./../../../src/pages/important-safety-information.js" /* webpackChunkName: "component---src-pages-important-safety-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-report-adverse-reactions-js": () => import("./../../../src/pages/report-adverse-reactions.js" /* webpackChunkName: "component---src-pages-report-adverse-reactions-js" */),
  "component---src-pages-savings-program-js": () => import("./../../../src/pages/savings-program.js" /* webpackChunkName: "component---src-pages-savings-program-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-support-materials-js": () => import("./../../../src/pages/support-materials.js" /* webpackChunkName: "component---src-pages-support-materials-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

