import AppProvider from "./src/codes/providers/appProvider";

// gatsby-browser.js
export const onClientEntry = () => {
    window.onload = () => {
        // Dynamically insert the global variables script
        const dnsScript = document.createElement("script");
        dnsScript.type = "text/javascript";
        dnsScript.innerHTML = `
            var globalDnsDeployment = false;
            var claripHost = "purdue.clarip.com";
            var claripCdnHost = "cdn.clarip.com";
            var clientName = "purdue";
        `;
        document.head.appendChild(dnsScript);

        // Define global variables
        window.globalDnsDeployment = false;
        window.claripHost = 'purdue.clarip.com';
        window.claripCookieConsentHost = "purdue.clarip.com";
        window.claripCdnHost = 'cdn.clarip.com';
        window.clientName = 'purdue';
        window.claripClientName = "purdue";
        window.dnsControllerType = 'option-2';
        window.doNotSellCookieName = 'clarip_dns_cookie';
        window.doNotSellCookieValue = 1;
        window.doNotSellCookieExpirationAge = 1825;
        window.enableEnforcementScope = false;
        window.claripCookieManagerIdentifier = "d4fa176464f525e3dfca";

        // Adding stylesheets for Do Not Sell and Cookie Consent
        const donotsellStylesheet = document.createElement('link');
        donotsellStylesheet.rel = "stylesheet";
        donotsellStylesheet.type = "text/css";
        donotsellStylesheet.href = 'https://cdn.clarip.com/purdue/donotsell/assets/css/donotsell-extended.min.css';
        document.head.appendChild(donotsellStylesheet);

        const cookieconsentStylesheet = document.createElement('link');
        cookieconsentStylesheet.rel = "stylesheet";
        cookieconsentStylesheet.type = "text/css";
        cookieconsentStylesheet.href = '//cdn.clarip.com/purdue/cookieconsent/assets/css/cookieconsent.min.css';
        document.head.appendChild(cookieconsentStylesheet);

        // Adding scripts for Do Not Sell and Cookie Consent
        const block3rdPartiesScript = document.createElement('script');
        block3rdPartiesScript.src = 'https://cdn.clarip.com/purdue/donotsell/assets/js/block-3rd-parties.min.js';
        document.body.appendChild(block3rdPartiesScript);

        const donotsellBlockScript = document.createElement('script');
        donotsellBlockScript.src = 'https://cdn.clarip.com/purdue/donotsell/assets/js/donotsell-block.min.js';
        document.body.appendChild(donotsellBlockScript);

        const cookieconsentScript = document.createElement('script');
        cookieconsentScript.src = '//cdn.clarip.com/purdue/cookieconsent/assets/js/cookieconsent.min.js';
        document.head.appendChild(cookieconsentScript);

        const cookieManagerScript = document.createElement('script');
        cookieManagerScript.src = '//cdn.clarip.com/purdue/cookieconsent/assets/js/clarip-cookie-manager.min.js';
        document.head.appendChild(cookieManagerScript);

        // Function to set the consent cookie
        function setConsentCookie() {
            console.log("Consent button clicked. Setting cookie...");

            const consentCookie = 'oxycontin_clarip_consent';
            const currentDomain = window.location.hostname;
            const cookieValue = 'true';
            const expiryDays = 365;

            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + expiryDate.toUTCString();

            document.cookie = consentCookie + "=" + cookieValue + ";" + expires + ";domain=" + currentDomain + ";path=/";
        }

        document.addEventListener('DOMContentLoaded', () => {
            const consentButton = document.querySelector('.cc-allow');
            if (consentButton) {
                console.log("Attaching event listener to '.cc-allow' button");
                consentButton.addEventListener('click', setConsentCookie);
            } else {
                console.log("'.cc-allow' button not found");
            }
        });

        setTimeout(() => {
            window.dispatchEvent(new Event('DOMContentLoaded'));
        }, 1000);
    };
};


export const wrapRootElement = AppProvider;
